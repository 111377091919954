import React from 'react'
import django from '../assets/django.png'
import css from '../assets/css.png'
import docker from '../assets/docker.png'
import github from '../assets/github.png'
import html from '../assets/html.png'
import java from '../assets/java.png'
import js from '../assets/js.png'
import python from '../assets/python.png'
import react from '../assets/react.png'
import spring_boot from '../assets/spring_boot.png'
import aws from '../assets/aws.png'

const Experience = () => {
    const techs = [
        {
            id:1,
            src:django,
            title:'Django',
            style:'shadow-green-500'
        },
        {
            id:2,
            src:python,
            title:'Python',
            style:'shadow-yellow-500'
        },
        {
            id:3,
            src:spring_boot,
            title:'Spring Boot',
            style:'shadow-green-500'
        },
        {
            id:4,
            src:java,
            title:'Java',
            style:'shadow-red-500'
        },
        {
            id:5,
            src:react,
            title:'React JS',
            style:'shadow-cyan-500'
        },
        {
            id:6,
            src:js,
            title:'JavaScript',
            style:'shadow-yellow-500'
        },
        {
            id:7,
            src:html,
            title:'HTML',
            style:'shadow-orange-500'
        },
        {
            id:8,
            src:css,
            title:'CSS',
            style:'shadow-blue-500'
        },
        {
            id:9,
            src:aws,
            title:'AWS',
            style:'shadow-gray-500'
        },
        {
            id:10,
            src:docker,
            title:'Docker',
            style:'shadow-blue-500'
        },
        {
            id:11,
            src:github,
            title:'Github',
            style:'shadow-gray-500'
        },
        
    ]
  return (
    <div name="experience" className="bg-gradient-to-b from-gray-800 to-black w-full h-screem">
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
            <div>
                <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Experience</p>
                <p className="py-6">These are the technologies I've worked with</p>
            </div>

            <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 ">
                {techs.map(({id,src,title,style}) => 
                    <div id={id} className={"shadow-md hover:scale-105 duration-500 py-2 rounded-lg "+style}>
                    <img src={src} alt="" className="w-20 mx-auto "/>
                    <p className="mt-4">{title}</p>
                </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Experience