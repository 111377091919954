import React from 'react'
import devSearch from '../assets/portfolio/DevSearch.jpg'
import notesapp from '../assets/portfolio/notesapp.jpg'
import pong from '../assets/portfolio/Pong.jpg'
import rewordle from '../assets/portfolio/ReWordle.jpg'
import tetris from '../assets/portfolio/Tetris.jpg'
import todoapp from '../assets/portfolio/todoapp.jpg'
import foodrecipe from '../assets/portfolio/FoodRecipe.jpg'

const Portfolio = () => {
    const portfolios = [
        {
            id:1,
            src: devSearch,
            demo:"",
            code:"https://gitlab.com/django-devsearch/devsearch",
            description:"DevSearch is a comprehensive and feature-rich Developer Social Network meticulously crafted with Django. This web application stands as a vibrant and inclusive digital haven where developers from all walks of life can connect, collaborate, and passionately share their expertise, experiences, and love for coding and technology."
        },
        {
            id:2,
            src: todoapp,
            demo:"",
            code:"https://gitlab.com/django-todo-app/todo-app",
            description:"The Django To-Do App is a versatile and user-friendly task management application designed to streamline your daily productivity. This web-based application offers a clean and intuitive interface to help users organize tasks, set priorities, and stay on top of their schedules effortlessly."
        },
        {
            id:3,
            src: notesapp,
            demo:"",
            code:"https://gitlab.com/notes-app3132837",
            description:"The Notes App developed using Django REST API / Spring Boot REST API and React.js showcases my proficiency in full-stack web development, from creating a RESTful backend to crafting an interactive and responsive frontend. It demonstrates my ability to build user-friendly applications with essential features like data storage, and data manipulation. This project reflects my commitment to delivering high-quality, practical solutions to real-world problems in the field of web development."
        },
        {
            id:4,
            src: foodrecipe,
            demo:"",
            code:"https://gitlab.com/food-recipe07",
            description:"The Food Recipe API, powered by Spring Boot, utilizes Hibernate for efficient database management and integrates JWT for enhanced security. Leveraging the strengths of Hibernate ensures seamless data interactions, while JWT brings robust authentication and authorization features. PostgreSQL serves as the reliable backend database, contributing to data integrity. This API is designed to be secure, scalable, and well-documented, offering developers and users a streamlined experience for exploring and integrating diverse culinary recipes."
        },
        {
            id:5,
            src: pong,
            demo:"https://replit.com/@MarcoIligan/Pong",
            code:"https://gitlab.com/pygame923836/Pong",
            description:"Welcome to my Pong game, a modern twist on the classic arcade game that showcases my Python programming skills and creativity. This retro-inspired game brings the excitement of table tennis to your screen with a dash of innovation and visual flair."
        },
        {
            id:6,
            src: rewordle,
            demo:"https://rwordle-clone.pages.dev/",
            code:"https://github.com/marcoiligan/rwordle",
            description:"Welcome to my Wordle Clone game, a web-based word puzzle that demonstrates my proficiency in ReactJS and my passion for creating engaging and interactive web applications. Wordle is a classic game that challenges your word-guessing skills and strategic thinking."
        },
        {
            id:7,
            src: tetris,
            demo:"https://replit.com/@MarcoIligan/Tetris",
            code:"https://gitlab.com/pygame923836/Tetris",
            description:"This Tetris game project reflects my dedication to game development and my proficiency in Python and Pygame. It serves as a testament to my ability to create enjoyable and interactive gaming experiences while maintaining code quality and organization. Feel free to explore the project and discover my passion for game development and coding."
        },
        
    ]

  return (
    <div name="portfolio" className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-full">
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
            <div className="pb-8">
                <p className="text-4xl font-bold inline border-b-4 border-gray-500">Portfolio</p>
                <p className="py-6">Check out some of my work right here</p>
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
            {portfolios.map(({id,src,demo,code, description}) => 
                <div id={id} className="shadow-md shadow-gray-600 rounder-lg">
                    <img src={src} alt="" className="rounded-md duration-200 hover:scale-105"/>
                    <div className="flex items-center justify-center">
                        {demo ? 
                        <a href={demo} rel="noreferrer" className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105" target="_blank">Demo</a> : <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105" >Demo</button>
                        }
                        {code ?
                        <a href={code} rel="noreferrer" className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105" target="_blank">Code</a> : <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105" >Code</button>
                        }
                    </div>
                    <p className="p-5 items-center justify-end">
                        {description}
                    </p>
                </div>
            )}
            </div>
        </div>
    </div>
  )
}

export default Portfolio